/*
  Resume Website project
  Author: Dan J. Hamilton
  Date:   15 February 2016

  Filename:         index.html
  Supporting files: modernizr-1.5.js, styles_main.css
*/
import React from "react";
import { Link } from "gatsby";

import SEO from "../../components/seo";
import Image from ".../../components/image"
import "../../styles/resume_styles.css";

export default () => (
  <>
    <SEO title="Resume of Dan J. Hamilton" />

    <div className="all-content">
      <div className="nav">   
        <ul>
          <li><Link to="/index-project/" title="Project Index">Project Index</Link></li>
          <li><Link to="/resume/" title="Welcome Page">Welcome Page</Link></li>
          <li><Link to="/resume/employmenthistory/" title="Resume">Resume</Link></li>
          <li><Link to="/resume/bio/" title="Biography">Biography</Link></li>
          <li><Link to="/resume/music/" title="Favorite Music">Favorite Music</Link></li>
          <li><Link to="/resume/food/" title="Favorite Food">Favorite Food</Link></li>
          <li><Link to="/resume/about/" title="About this Website">About this Website</Link></li>
        </ul>
      </div>

      <section className="resume-main-content">
        <div className="section-header">
          <h1>Welcome</h1>
        </div>

        <Image className="img-float-left" value="portrait" alt="Dan"/>

        <p id="firstp">
          Hello!  I am Dan J. Hamilton.<br /><br />
      
          After a 27-year career as an actuary, I am trying to decide what to do as an encore. 
          On many occasions, I engaged in what I call programming lite.  I would learn enough 
          programming technology, such as VBA or SQL, to automate repetitive tasks.

          <br /><br />
          I attended several courses at Valencia College in computer programming. I enjoyed them.
          The ability to solve a problem with code is a rush!

          <br /><br />
          Presently, I continue my studies online.  Now I need to narrow my new passion to a specific
          area: front-end, back-end, full-stack, data analyst, machine learning.
          So many choices; each with their challenges
        </p>

        <Image className="img-float-right" value="tskLogo" alt="TSK Solutions"/>
      </section>
      
      <div className="footer">
        <div className="address">
            Winter Garden, FL &nbsp;34787
        </div>
      </div>
    </div>
  </>
)